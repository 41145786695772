import React, {useEffect, useState} from 'react';
import PageSection from "../components/PageSection";
import {Row} from "react-bootstrap";
import FormGroup from "../components/FormGroup";
import {useForm} from "react-hook-form";
import SwitchToggle from "../components/Layaout/SwitchToggle";
import PageCardTitle from "../components/Layaout/CardTitle";
import {Button, Card} from "@wargostec/react-components";
import {Apis} from "../util/Apis";
import SelectMaker from "../Selects/SelectMaker";
import {Modalverification} from "../components/forms/ModalVerification";
import useEditModal from "../hooks/useEditModal";
import axios from "axios";
import GameEdit from "./Arrays/GameEdit";
import GameNew from "./Arrays/GameNew";
import GenericModal2 from "../hooks/GenericModal2";
import Input, {SelectV2} from "../components/forms/FormEl";
import {Table} from "../components/forms/Table";
import useFetch from "../hooks/useFetch";
import SelectConnector from "../Selects/SelectConnector";

const ModelForm = ({value, onSubmit, mode, fetchData}) => {


  const {
    register,
    reset,
    handleSubmit,
    formState: {errors}
  } = useForm();

  const deleteGame = id => {
    axios.delete(Apis.SALAS + `/slot-model/` + id + "/remove-game")
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })
  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <GameEdit/>,
    addComponent: <GameNew/>
  })

  const columns = [
    {Header: 'Nombre', accessor: 'name'},
    {
      Header: 'Acciones',
      Cell: ({cell}) => {
        const [isDelete, setIsDelete] = useState(false);
        return (
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(cell.row.original.id)}/>
            <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
            <Modalverification
              show={isDelete}
              handleClose={() => setIsDelete(false)}
              title='¿Seguro que desea eliminar este juego?'
              handleDelete={() => deleteGame(cell.row.original.id)}
            />
          </div>
        );
      },
    },
  ]

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    if (refresh1 > 1) {
      mode && fetchData()
    }
  }, [refresh1])

  return (
    <div>
      <PageCardTitle title={'General'}/>
      <Card>
        <PageSection style={{paddingTop: '20px'}}>
          <Row>
            <FormGroup>
              <label>Id</label>
              <Input type={'number'} {...register("modelId",{required: "Campo obligatorio"})}/>
              {errors.modelId && <p style={{color: 'red'}}>{errors.modelId?.message}</p>}
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>NOMBRE</label>
              <Input type={'text'} {...register('name')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>TIPO</label>
              <SelectV2 options={
                [
                  {id: 1, name: 'MAQUINA'},
                  {id: 2, name: 'RULETA'},
                  {id: 3, name: 'DERBY'},
                  {id: 4, name: 'MESAS'},
                ]
              }  {...register('machineTypeId', {required: "Campo obligatorio"})}/>
              {errors.machineTypeId && <p style={{color: 'red'}}>{errors.machineTypeId?.message}</p>}
            </FormGroup>
          </Row>

          <FormGroup>
            <label>ALIAS</label>
            <Input type={'text'} {...register('nickName')}/>
          </FormGroup>

          {
            mode &&
            <Row>
              <FormGroup>
                <label>
                  JUEGOS
                </label>
                {
                  value &&
                  <>
                    <Button variant={'primary'} size='s' onClick={() => addAction()}>Agregar</Button>
                    <div>
                      <Table columns={columns} data={value.games}/>
                    </div>
                  </>
                }
              </FormGroup>
            </Row>
          }

          <Row>
            <FormGroup>
              <label>FABRICANTE</label>
              <SelectMaker {...register('manufacturerId')}>
              </SelectMaker>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>#PUERTOS</label>
              <Input placeholder={"Ingrese el texto"} type={'number'} {...register('comPortsNumber')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>CONECTOR</label>
              <SelectConnector {...register('connectorId')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>¿Soporta transferencia de creditos?</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("transferCreditsSupport")}/>
            </FormGroup>
          </Row>
        </PageSection>
      </Card>


      <PageCardTitle title={'Detalles extras'}/>
      <Card>
        <PageSection style={{paddingTop: '20px'}}>
          <Row>
            <FormGroup>
              <label>PRECIO $(Aprox) </label>
              <Input placeholder={"Ingrese el texto"} type={'number'} {...register('price')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>ANTIGUEDAD</label>
              <Input placeholder={"Ingrese el texto"} type={'text'} {...register('protocol')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>TIPO DE GABINETE</label>
              <Input placeholder={"Ingrese el texto"} type="text" {...register('cabinet')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Link de foto</label>
              <Input placeholder={"Ingrese el texto"} type="text" {...register('photoLink')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Link de conector</label>
              <Input placeholder={"Ingrese el texto"} type="text" {...register('connectorLink')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Comentarios</label>
              <Input placeholder={"Ingrese el texto"} type="text" {...register('comment')}/>
            </FormGroup>
          </Row>
        </PageSection>
      </Card>
      <div>
        <Button variant={'primary'} onClick={handleSubmit(onSubmit)} text={'Guardar'}/>
      </div>
      <GenericModal2 {...wrapper1}/>
    </div>
  )
    ;
};

export default ModelForm;
